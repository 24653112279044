<template>
	<div
		class="overlay"
		@click="click"
	/>
</template>

<script>
export default {
	props: {
		click: {
			type: Function,
			default: () => {}
		}
	}
};
</script>


<style lang="scss">
	.overlay {
		transition: opacity 0.3s, transform 0s 0.3s;
		content: '';
		pointer-events: none;
		opacity: 0;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 11;
		width: 100%;
		height: 100%;
		background: rgba($color-black, 0.8);
		backdrop-filter: blur(3px);
	}
</style>
